import img1 from "./img/Teams.webp";
import img2 from "./img/Teams.jpg";
import img3 from "./img/team.jpg";
import img4 from "./img/teamnew.jpg";

const CarouselImg = [
  {
    img: img1,
    name: "Image1",
  },
  {
    img: img2,
    name: "Image1",
  },
  {
    img: img3,
    name: "Image1",
  },
  {
    img: img4,
    name: "Image1",
  },
];

export default CarouselImg;
