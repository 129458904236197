import gimg1 from "../../asset/galleryimg1.jpg";
import gimg2 from "../../asset/galleryimg2.jpg";
import gimg3 from "../../asset/galleryimg3.jpg";
import gimg4 from "../../asset/galleryimg4.jpg";
import gimg5 from "../../asset/galleryimg5.jpg";
import gimg6 from "../../asset/galleryimg6.jpg";
import gimg7 from "../../asset/galleryimg7.jpg";
import gimg8 from "../../asset/galleryimg8.jpg";
import gimg9 from "../../asset/galleryimg9.jpg";
import gimg10 from "../../asset/galleryimg10.jpg";
import gimg11 from "../../asset/galleryimg11.jpg";
import gimg12 from "../../asset/galleryimg12.jpg";
import gimg13 from "../../asset/galleryimg13.jpg";
import gimg14 from "../../asset/galleryimg14.jpg";
import gimg15 from "../../asset/galleryimg15.jpg";
import gimg16 from "../../asset/galleryimg16.jpg";
import gimg17 from "../../asset/galleryimg17.jpg";
import gimg18 from "../../asset/galleryimg18.jpg";
import gimg19 from "../../asset/galleryimg19.jpg";
const GalleryData = [
  {
    img: gimg1,
    title: "Conference",
  },
  {
    img: gimg2,
  },
  {
    img: gimg3,
  },
  {
    img: gimg4,
  },
  {
    img: gimg5,
  },
  {
    img: gimg6,
  },
  {
    img: gimg7,
  },
  {
    img: gimg8,
  },
  {
    img: gimg9,
  },
  {
    img: gimg10,
  },
  {
    img: gimg11,
  },
  {
    img: gimg12,
  },
  {
    img: gimg13,
  },
  {
    img: gimg14,
  },
  {
    img: gimg15,
  },
  {
    img: gimg16,
  },
  {
    img: gimg17,
  },
  {
    img: gimg18,
  },
  {
    img: gimg19,
  },
];
export default GalleryData;
